import './Style.css';

const App = () => {
  //const [user, setUser] = useState([]);
  //const [uname, setUName] = useState('');
  //const [pass, setPass] = useState('');

  /*useEffect(() => {
    axios.get('https://server.iltechs.in/api/users')
      .then(response => {
        setUser(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the users!', error);
      });
  }, []);

  const addUser = () => {
    axios.post('https://server.iltechs.in/api/users', { uname, pass })
      .then(response => {
        alert(response.data.message);
        setUser([...user, { id: response.data.id, uname, pass }]);
        setUName('');
        setPass('');
      })
      .catch(error => {
        console.error('There was an error adding the user!', error);
      });
  };*/

  return (
    <div className='main'>
      <img className='logo' src='https://iltechs.in/image/logo/iltechs_official_logo.png' alt='ILTECHS' />
      <img className='poster' src='https://iltechs.in/image/coming-soon-sooner.jpg' alt='COMING SOON' />
    </div>
  );
};

export default App;
